import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

function FileUploadDropZone({onUploadCallback, instanceId}) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // Add the accepted files to the state
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    // Call the onUpload callback
    onUploadCallback(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSendFiles = (event) => {
    event.preventDefault();
    const formData = new FormData();
    files.forEach((file) => formData.append('file', file));
    const json = { instanceId: instanceId }; // Replace with your own JSON data
    formData.append('json', new Blob([JSON.stringify(json)], { type: 'application/json' }));
    fetch('http://your-server.com/upload', {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      console.log('Files uploaded successfully');
    }).catch((error) => {
      console.error('Error uploading files', error);
    });
  };

  const removeFile = (index, event) => {
    event.stopPropagation();
    // Create a new array that excludes the file at the specified index
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };


  return (
    <div className="container">
      <form id="file-upload-form">
        <div {...getRootProps()} className="jumbotron text-center">
          <input {...getInputProps()} />
          <p className="lead">
            {
              isDragActive ?
                'Drop the files here ...' :
                'Drag and drop some files here, or click to select files'
            }
          </p>
         {files.map((file, index) => (
            <div key={file.name} className="d-flex align-items-center">
              <p className="mr-3 mb-0">{file.name}</p>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={(event) => removeFile(index, event)}
              >
                X
              </button>
            </div>
          ))}
          <button className="btn btn-primary" type="button">
            Select Files
          </button>         
        </div>
         <button className="btn btn-warning" type="button" onClick={onSendFiles}>
            Send Files
          </button>
      </form>
    </div>
  );
}

export default FileUploadDropZone;
