import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      users: [],
      user: []
    };
  }

  async componentDidMount() {

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/all/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              users: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Χρήστες</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.user.PersonRole == 'Admin' ?
                        <>
                          <Button
                            color="success"
                            href={"/admin/newuser"}
                            size="sm"
                          >
                            Προσθήκη νέου
                          </Button>
                        </> : <> </>}
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονοματεπωνυμο</th>
                      <th scope="col">Email</th>
                      <th scope="col">Ρολος</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((dynamicData) =>
                      <tr>
                        <td>{dynamicData.DisplayName}</td>
                        <td>{dynamicData.Email}</td>
                        <td>{dynamicData.Roles}</td>
                        <td><Button
                          color="primary"
                          href={"/admin/user/" + dynamicData.Id}
                          size="sm"
                        >
                          Επεξεργασία
                        </Button>
                          {this.state.user.PersonRole == 'Admin' ?
                            <>
                              <Button
                                color="danger"
                                // onClick={() => { this.handleClick(dynamicData.Id) }}
                                size="sm"
                                title="Διαγραφή"
                              >
                                <i className="fas fa-trash" />
                              </Button>
                            </> : <> </>}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
