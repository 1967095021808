import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

class InstanceMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      user: []
    };
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ενεργές Επιθεωρήσεις</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/instances/"}
                        size="sm"
                      >
                        Προβολή όλων

                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ιστορικό Επιθεωρήσεων</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/oldinstances/"}
                        size="sm"
                      >
                        Προβολή όλων

                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default InstanceMenu;
