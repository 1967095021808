import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class GenericHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{background: "linear-gradient(to right, #00467F, #5CBC59)"}}>
          <Container fluid>
            <div className="header-body">
              {/* <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Συνολικες Ροες
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            350,897
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default GenericHeader;
