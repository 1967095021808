import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      role: '',
      name: '',
      email: '',
      user: []
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    // console.log(handle)

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/users/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
              name: result.DisplayName,
              email: result.Email,
              role: result.Roles
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log(result);
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          // console.log(error);
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  resetPassword = userId => {
    if (localStorage.getItem('token')) {
      axios.put(process.env.REACT_APP_API_LINK + '/api/auth/resetpassword/' + userId + '/', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          // console.log(res.data);
        })
    }
  }

  updateName(evt) {
    // console.log(evt.target.value);
    this.setState({
      name: evt.target.value
    })
  }

  updateRole(evt) {
    // console.log(evt.target.value);
    this.setState({
      role: evt.target.value
    })
  }

  updateEmail(evt) {
    // console.log(evt.target.value);
    this.setState({
      email: evt.target.value
    })
  }

  saveChanges = userId => {
    if (localStorage.getItem('token')) {
      axios.put(process.env.REACT_APP_API_LINK + '/api/user/update/', {
        data: { 'DisplayName': this.state.name, 'Email': this.state.email, 'Roles': this.state.role },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          // console.log(res.data);
        })
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πληροφορίες χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == 'Admin' ?
                        <>
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={() => { this.saveChanges(this.state.user.Id) }}
                            size="sm"
                          >
                            Αποθήκευση αλλαγών
                          </Button>
                        </> : <> </>}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ονοματεπώνυμο
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.DisplayName}
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.Email}
                              id="input-email"
                              placeholder=""
                              type="email"
                              onChange={evt => this.updateEmail(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ρόλος
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.Roles}
                              id="input-first-name"
                              type="select"
                              onChange={evt => this.updateRole(evt)}
                            >
                              {this.state.roles.map((dynamicRoles) =>
                                <option>{dynamicRoles.Name}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {this.state.user.PersonRole == 'Admin' ?
                      <>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Επαναφορα κωδικου προσβασης
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="4" style={{ alignTextAlign: "center" }}>
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => { this.resetPassword(this.state.user.Id) }}
                                size="sm"
                              >
                                Επαναφορά κωδικού
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </> : <> </>}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default User;
