import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';
import Modal from 'react-modal';
import DatePicker from "react-multi-date-picker";

import 'antd/dist/antd.css';
import { Cascader } from 'antd';

import FileUploadDropZone from '../../components/FileUploadDropZone'
import {
    Button,
    Collapse,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Tooltip
} from "reactstrap";

import Select from 'react-select'
import 'antd/dist/antd.css';


import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

import Calendar from 'react-input-calendar'

const epitheorisi_options = [
    // { value: 'default', label: 'Επιλέξε' },
    { value: 'Νέα Επιθεώρηση', label: 'Νέα Επιθεώρηση' },
    { value: 'Ορθή Επανάληψη', label: 'Ορθή Επανάληψη' },
]

const accepted_options = [
    {
        value: 'ξύλινων στύλων',
        label: 'Ξύλινοι Στύλοι',
        children: [
            {
                value: 'Αποδεκτή ποσότητα εμποτισμένων',
                label: 'Αποδεκτοί Εμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα εμποτισμένων',
                label: 'Απορριφθέντες Εμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα επανεμποτισμένων',
                label: 'Αποδεκτοί Επανεμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα επανεμποτισμένων',
                label: 'Απορριφθέντες Επανεμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα ανεμπότιστων',
                label: 'Αποδεκτοί Ανεμπότιστοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα ανεμπότιστων',
                label: 'Απορριφθέντες Ανεμπότιστοι',
            },
        ],
    },
    {
        value: 'ξύλινων βραχιόνων',
        label: 'Ξύλινοι Βραχίονες',
        children: [
            {
                value: 'Αποδεκτή ποσότητα εμποτισμένων',
                label: 'Αποδεκτοί Εμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα εμποτισμένων',
                label: 'Απορριφθέντες Εμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα επανεμποτισμένων',
                label: 'Αποδεκτοί Επανεμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα επανεμποτισμένων',
                label: 'Απορριφθέντες Επανεμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα ανεμπότιστων',
                label: 'Αποδεκτοί Ανεμπότιστοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα ανεμπότιστων',
                label: 'Απορριφθέντες Ανεμπότιστοι',
            },
        ],
    },
    {
        value: 'ξυλοδοκών αγκύρωσης',
        label: 'Ξυλοδοκοί Αγκύρωσης',
        children: [
            {
                value: 'Αποδεκτή ποσότητα εμποτισμένων',
                label: 'Αποδεκτοί Εμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα εμποτισμένων',
                label: 'Απορριφθέντες Εμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα επανεμποτισμένων ',
                label: 'Αποδεκτοί Επανεμποτισμένοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα επανεμποτισμένων ',
                label: 'Απορριφθέντες Επανεμποτισμένοι',
            },
            {
                value: 'Αποδεκτή ποσότητα ανεμπότιστων',
                label: 'Αποδεκτοί Ανεμπότιστοι',
            },
            {
                value: 'Απορριφθείσα ποσότητα ανεμπότιστων',
                label: 'Απορριφθέντες Ανεμπότιστοι',
            },
        ],
    },
];

const options = [
    {
        value: 'ξύλινων στύλων',
        label: 'Ξύλινοι Στύλοι',
        children: [
            {
                value: ' εμποτισμένων',
                label: 'Εμποτισμένοι',
            },
            {
                value: 'ανεμπότιστων',
                label: 'Ανεμπότιστοι',
            },
            {
                value: 'επανεμποτισμένων',
                label: 'Επανεμπότιστοι',
            },
        ],
    },
    {
        value: 'ξύλινων βραχιόνων',
        label: 'Ξύλινοι Βραχίονες',
        children: [
            {
                value: ' εμποτισμένων',
                label: 'Εμποτισμένοι',
            },
            {
                value: 'ανεμπότιστων',
                label: 'Ανεμπότιστοι',
            },
            {
                value: 'επανεμποτισμένων',
                label: 'Επανεμπότιστοι',
            },
        ],
    },
    {
        value: 'ξυλοδοκών αγκύρωσης',
        label: 'Ξυλοδοκοί Αγκύρωσης',
        children: [
            {
                value: ' εμποτισμένων',
                label: 'Εμποτισμένοι',
            },
            {
                value: 'ανεμπότιστων',
                label: 'Ανεμπότιστοι',
            },
            {
                value: 'επανεμποτισμένων',
                label: 'Επανεμπότιστοι',
            },
        ],
    }
];

class Instance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            loading: true,
            instance: [],
            workflow: [],
            locations_dropdown: [],
            suppliers_dropdown: [],
            user: [],
            logged_user: [],
            steps: [],
            files: [],
            fileFirstStatus: false,
            fileThirdStatus: false,
            enabled: true,
            modalFieldName: '',
            modalFieldStepId: '',
            modalFieldActionId: '',
            fileUpload: null,
            comment: '',
            buttonCondition: 0,
            buttonMessage: '',
            valueto: '',
            datesSelected: [],
            showAddNewButton: true,
            showRepeatNumber: false,
            showTextAboutRepeat: false,
            acceptedShowAddNewButton: true,
            category_set: [false, false, false, false, false, false, false, false, false],
            accepted_category_set: [false, false, false, false, false, false, false, false, false, false, false, false],
            categoriesSubcategoriesDict: {},
            acceptedCategoriesSubcategoriesDict: {}


        };
        // this.handleSubmit = this.handleSubmit.bind(this);

        this.selectorRef = React.createRef(null);
        this.handleCloseModalSubmit = this.handleCloseModalSubmit.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instance/" + handle + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    instance: res.data.instance,
                    workflow: res.data.workflow,
                    user: res.data.user,
                    logged_user: res.data.logged_user,
                    steps: res.data.stepsInfused,
                    showModal: false,
                    modalFieldName: '',
                    modalFieldStepId: '',
                    modalFieldActionId: '',
                    fileUpload: null,
                    comment: '',
                    loading: false,
                });

                if (res.data.stepsInfused[1].actions[1].data.Data) {
                    let dates_string = res.data.stepsInfused[1].actions[1].data.Data;

                    let dates_array = dates_string.split(",");

                    this.setState({
                        datesSelected: dates_array
                    });
                }

                res.data.stepsInfused[0]['actions'].forEach((actionSet, index) => {

                    if (actionSet['action']['Name'].includes("Ποσότητα"))
                        this.state.categoriesSubcategoriesDict[actionSet['action']['Name']] = [actionSet['action']['WorkflowStepId'], actionSet['action']['Id']]

                })

                res.data.stepsInfused[2]['actions'].forEach((actionSet, index) => {

                    if (actionSet['action']['Name'].includes("ποσότητα"))
                        this.state.acceptedCategoriesSubcategoriesDict[actionSet['action']['Name']] = [actionSet['action']['WorkflowStepId'], actionSet['action']['Id']]
                    // console.log(this.state.acceptedCategoriesSubcategoriesDict);
                })


                if (res.data.instance.Completed)
                    document.getElementById('approveInstanceButton').style.display = 'none';

                // console.log(res.data.stepsInfused);
            })
        //this.getFiles;
        axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    loading: false,
                    files: res.data
                });
                this.state.files.forEach(file => {
                    if (file.StepId == "85d20ba6-3ff1-402c-afc2-16704ecffcf4") {
                        this.setState({
                            fileFirstStatus: true
                        })
                    }
                    else if (file.StepId == "1d628f31-bcfa-4acc-a271-ed3303ba0584") {
                        this.setState({
                            fileThirdStatus: true
                        })
                    }
                })
                console.log(res);
            })

        fetch(process.env.REACT_APP_API_LINK + "/api/supplier/all/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        suppliers: result,
                    });
                    let newArray = this.state.suppliers.map(item => ({ value: item.SupplierName, label: item.SupplierName }))
                    this.setState({
                        suppliers_dropdown: newArray
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(process.env.REACT_APP_API_LINK + "/api/location/all/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        locations: result,
                    });
                    let newArray = this.state.locations.map(item => ({ value: item.LocationName, label: item.LocationName }))
                    this.setState({
                        locations_dropdown: newArray
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )


    };

    handleOpenModal(evt, fieldName) {
        var res = evt.split("/");
        // console.log(evt);

        this.setState({
            modalFieldName: fieldName,
            modalFieldStepId: res[0],
            modalFieldActionId: res[1],
            showModal: true,
        });
    }

    handleCloseModalSubmit() {
        // console.log(this.state.comment);
        fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/addcomment/', {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
                'InstanceId': this.state.instance.Id,
                'StepId': this.state.modalFieldStepId,
                'ActionId': this.state.modalFieldActionId,
                'Comment': this.state.comment
            })
        })
            .then(data => {
                // console.log(data);
            })

        this.setState({ showModal: false });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    saveInstance = stepStatus => {
        // console.log(this.state.steps);

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/update/' + this.state.instance.Id + "/", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {
                    // console.log(this.state.steps[stepStatus - 1]);
                })
            // console.log(this.state.steps[stepStatus - 1].step.Status);
        }
    }

    approveInstance = stepStatus => {
        document.getElementById("serialdatestatusmessage").innerHTML = "";
        //// console.log("edo");
        // console.log(this.state.steps[stepStatus - 1]);
        //// console.log("edo");
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/checkserialanddate/' + this.state.instance.Id + "/", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    if (!data.alreadyexists || stepStatus - 1 > 1) {
                        if (localStorage.getItem('token')) {
                            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/approve/' + this.state.instance.Id + "/", {
                                method: 'POST',
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                }),
                                body: JSON.stringify(this.state.steps[stepStatus - 1])
                            })
                                .then(data => {
                                    // console.log(data);

                                    window.location.reload(false);
                                })
                        }
                    }
                    else {
                        document.getElementById("serialdatestatusmessage").innerHTML = "<p style='color:red'>Αυτή η εγγραφή χρησιμοποιείται ήδη. Επιλέξτε άλλον Αριθμό Αναγγελίας.</p>";
                    }
                })
        }
    }
    getFiles = () => {
        axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    loading: false,
                    files: res.data
                });
            })

    }
    orthiEpanalipsi = () => {

        fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/orthiepanalipsi/" + this.state.instance.Id + "/", {
            method: 'get',
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({ showTextAboutRepeat: true });
            })
    }
    declineInstance = stepStatus => {
        // console.log(this.state.steps);

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/decline/' + this.state.instance.Id + "/", {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {
                    // console.log(data);

                    window.location.replace("/admin/details/" + this.state.instance.Id);
                })
        }
    }

    dynamicCall(call) {
        // console.log(call);

        if (call == 'check_meter') {
            // console.log('check meter !');
            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + "/api/meters/instance/" + this.state.instance.Id + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        // console.log(data);
                        if (data.details.Id == 'Δεν υπάρχει ακόμα μετρητής.') {
                            this.setState({
                                buttonMessage: 'Δεν υπάρχει μετρητής στο ακίνητο που βρίσκεται η αίτηση.',
                                buttonCondition: 3
                            });

                        }
                        else {
                            this.setState({
                                buttonMessage: 'Υπάρχει μετρητής στο ακίνητο που βρίσκεται η αίτηση.',
                                buttonCondition: 3
                            });
                            window.open('http://192.168.1.226:3000/admin/meter/' + data.details.Id, '_blank');
                        }
                    });
            }
        }
        else if (call == 'calc_price') {
            // console.log('calculate price');
            var price = document.getElementById('1feedef1-df5f-4905-b68e-c0b8d9896ad7/a211af46-4813-4ca7-8f7a-2d4591474cd1').value;

            if (price > 0) {
                // console.log(parseFloat(price));
            }
            else {
                price = 0;
                // console.log(price);
            }

            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/pricing/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'Price': price,
                        'InstanceId': this.state.instance.Id
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({
                            buttonMessage: 'Yπολογίστηκαν τα τέλη σύνδεσης στα ' + data.Price + '€.',
                            buttonCondition: 4
                        });
                    })
            }
        }
        else if (call == 'check_supp') {
            // console.log('check supplier');
            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + "/api/suppliers/instance/" + this.state.instance.Id + '/', {
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        // console.log(data);
                        if (data.SupplierId == 'Δεν υπάρχει ακόμα') {
                            this.setState({
                                buttonMessage: 'Δεν έχει συνδεθεί ακόμα προμηθευτής με την αίτηση.',
                                buttonCondition: 8
                            });
                        }
                        else {
                            this.setState({
                                buttonMessage: 'Συνδέθηκε προμηθευτής με την αίτηση.',
                                buttonCondition: 8
                            });
                        }
                    });
            }
        }
        else if (call == 'req_aut') {
            // console.log('request autopsy');

            fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/autopsy/" + this.state.instance.Id + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                });

            this.setState({
                buttonMessage: 'Αιτηθήκατε για αυτοψία. Θα ενημερωθεί το αρμόδιο τμήμα για το αίτημά σας.',
                buttonCondition: 9
            });
        }
        else if (call == 'mech_comp') {
            // console.log('mechanical completion');

            this.setState({
                buttonMessage: 'Έγινε με επιτυχία η μηχανική ολοκλήρωση.',
                buttonCondition: 6
            });
        }
        else if (call == 'create_ikasp') {
            if (localStorage.getItem('token')) {
                fetch(process.env.REACT_APP_API_LINK + '/api/ikasp/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'InstanceId': this.state.instance.Id
                    })
                })
                    .then(res => res.json())
                    .then(data => {
                        // console.log(data.Id);
                        this.setState({
                            buttonMessage: data.Ikasp,
                            buttonCondition: 4
                        });
                    })
            }
        }
    }

    uploadFile(evt, actionid) {
        const formData = new FormData();
        var res = actionid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        // // console.log(evt.target.files[0]);
        // // console.log(formData);


        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                // // console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg',
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': res[0],
                        'ActionId': res[1]
                    })
                })
                    .then(data => {
                        // // console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                            // headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg'}
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    updateFile(evt, test) {
        // // console.log(test);
        this.setState({ fileUpload: evt.target.files[0] });
    }

    deleteFile = fileId => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                        .then(res => {
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                            // // console.log(res);
                        })
                })
        }
    }

    updateInputValue(evt, value) {
        var res;
        let data;
        let list = [];
        // // console.log(evt.target.value);

        if (value) {
            // // console.log(value.toString());
            res = value.split("/");
            data = [...this.state.steps];

            let data_of_dates = evt;

            data_of_dates.forEach(function (item, index) {
                let day = item.day;
                let month = item.month.number;
                let year = item.year;
                let dateString = day + "-" + month + "-" + year;
                list.push(dateString);
            }, data_of_dates);

        }
        else {
            // // console.log(value);
            res = evt.target.id.split("/");
            // console.log(res);
            data = [...this.state.steps];

        }
        //changed by DZ in order to accept datepicker plugin id, values.. Not working yet.


        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        if (!value)
                            tempAction.data.Data = evt.target.value;
                        else
                            tempAction.data.Data = list.toString();

                        // console.log(data[i].actions[j])

                        data[i].actions[j] = tempAction;

                        this.state.steps = data;

                        // console.log(evt.target.value);

                        // this.setState({
                        //     steps: data
                        // });

                        // // console.log(this.state.steps[i].actions[j].action.Data);
                        // this.state.steps[i].actions[j].action.Data = evt.target.value;
                        // // console.log(this.state.steps[i].actions[j].action.Data);
                    }
                }
            }
        }

        // console.log(this.state.steps);
    }

    // multidateEventHandler = (e, id) => {
    //     // console.log(e);


    //     let data = e;

    //     let list = [];

    //     data.forEach(function(item,index){
    //         let day = item.day;
    //         let month = item.month.number;
    //         let year = item.year;
    //         let dateString = day + "-" + month + "-" +year;
    //         list.push(dateString);
    //     }, data);

    //     // this.setState({
    //     //     datesSelected: list
    //     // });

    //     this.state.datesSelected = list;

    //     // console.log(this.state.datesSelected);
    // }

    updateComment(evt) {
        this.setState({
            comment: evt.target.value
        });
    }

    handleNewCategory = (e) => {

        e.preventDefault();

        let counter = 0;


        this.state.category_set.forEach((set, index) => {

            if (set == false) {
                this.state.category_set[counter] = true;
                this.forceUpdate()
                return;
            }
            counter++;

            if (counter == (this.state.category_set.length - 1)) {

                this.state.showAddNewButton = false;
                this.forceUpdate()

            }

        })

    }

    acceptedHandleNewCategory = (e) => {

        e.preventDefault();

        let counter = 0;


        this.state.accepted_category_set.forEach((set, index) => {

            if (set == false) {
                this.state.accepted_category_set[counter] = true;
                this.forceUpdate()
                return;
            }
            counter++;

            if (counter == (this.state.accepted_category_set.length - 1)) {

                this.state.acceptedShowAddNewButton = false;
                this.forceUpdate()

            }

        })

    }

    handleCascaderChange = (e, classname) => {
        try {

            const res = [...e];

            var choise = res[1] + ' ' + res[0];

            for (const [key, value] of Object.entries(this.state.categoriesSubcategoriesDict)) {

                if (key.includes(choise)) {

                    // console.log(this.state.categoriesSubcategoriesDict[key]);
                    document.querySelector("." + classname).setAttribute("id", this.state.categoriesSubcategoriesDict[key][0] + '/' + this.state.categoriesSubcategoriesDict[key][1]);
                    document.querySelector("." + classname).removeAttribute("disabled");
                }
            }


        }
        catch {
            document.querySelector("." + classname).setAttribute("disabled", true);

        }
    }

    acceptedHandleCascaderChange = (e, classname) => {
        try {

            const res = [...e];
            var choise = res[1] + ' ' + res[0];

            // console.log(choise);

            for (const [key, value] of Object.entries(this.state.acceptedCategoriesSubcategoriesDict)) {

                if (key.includes(choise)) {

                    // console.log(this.state.acceptedCategoriesSubcategoriesDict[key]);
                    document.querySelector("." + classname).setAttribute("id", this.state.acceptedCategoriesSubcategoriesDict[key][0] + '/' + this.state.acceptedCategoriesSubcategoriesDict[key][1]);
                    document.querySelector("." + classname).removeAttribute("disabled");
                }
            }


        }
        catch {
            document.querySelector("." + classname).setAttribute("disabled", true);

        }
    }

    selectChange = (evt, id) => {
        var res = id.split("/");
        let data = [...this.state.steps];


        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    let tempAction = { ...data[i].actions[j] };
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        if (tempAction.action.Type == 'dropdown') {

                            console.log(evt.label);
                            tempAction.data.Data = evt.label;
                            data[i].actions[j] = tempAction;
                        }
                    }

                }
            }

        }

        if (evt.value == 'Ορθή Επανάληψη') {
            this.setState({
                showRepeatNumber: true
            })
        }
        else {
            this.setState({
                showRepeatNumber: false
            })
        }

        //console.log(id);
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            return (
                <>
                    {/* <FileUploadDropZone 
                    onUploadCallback={()=>{alert('onUploadCallback')}}
                    
                    instanceId={'123'}
                    /> */}
                    <InstanceHeader />
                    <div className="header pb-4" style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            ΔΕΔΔΗΕ Επιθεωρήσεις Ξύλινων Υλικών
                                                        </span>
                                                        <CardBody>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Α/Α: &nbsp; </span> {this.state.instance.SerialNumber}/{new Date().getFullYear()}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Έναρξης: &nbsp; </span> {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                            <Row className="align-items-center"><br></br></Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <Accordion defaultActiveKey={this.state.instance.Status}>
                        {this.state.steps.map((dynamicData) =>
                            <Container className="mt-2" fluid>
                                <Row className="mt-5">
                                    <Col className="order-xl-1" xl="12">
                                        <Card className="bg-secondary shadow">
                                            <CardHeader className="bg-white border-0">
                                                <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
                                                    <Row className="align-items-center">
                                                        <Col xs="8">
                                                            <h3 className="mb-0">{dynamicData.step.Status}. {dynamicData.step.Name}</h3>
                                                            {dynamicData.step.Status < this.state.instance.Status ?
                                                                <>
                                                                    {/* <p>Υποβλήθηκε {Moment(dynamicData.actions[0].data.DateCreated, "YYYYMMDD").locale('el').fromNow()}</p> */}
                                                                    <p>Υποβλήθηκε {Moment(dynamicData.actions[0].data.DateCreated).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</p>
                                                                </> : <></>
                                                            }
                                                        </Col>
                                                        {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                            <>
                                                                <Col className="text-right" xs="4">
                                                                    {dynamicData.step.Status < this.state.instance.Status || (dynamicData.step.Status == this.state.instance.Status && dynamicData.step.Status == 4 && this.state.instance.Completed == true) ?
                                                                        <>
                                                                            <Button
                                                                                // disabled={((dynamicData.step.Status == 1 && this.state.fileFirstStatus) || (dynamicData.step.Status == 3 && this.state.fileThirdStatus) || dynamicData.step.Status == 2 || dynamicData.step.Status == 4) ? false : true}
                                                                                color="primary"
                                                                                onClick={() => { this.saveInstance(dynamicData.step.Status) }}
                                                                                size="sm"
                                                                            >
                                                                                Αποθήκευση
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                    {dynamicData.step.Status == this.state.instance.Status ?
                                                                        <>

                                                                            <Button
                                                                                id="approveInstanceButton"
                                                                                color="success"
                                                                                onClick={() => { this.approveInstance(dynamicData.step.Status) }}
                                                                                size="sm"
                                                                            >
                                                                                Έγκριση
                                                                            </Button>
                                                                            <div id="serialdatestatusmessage"></div>

                                                                            {/* <Button
                                                                color="danger"
                                                                onClick={() => { this.declineInstance(dynamicData.step.Status) }}
                                                                size="sm"
                                                            >
                                                                Επανεξέταση
                                                            </Button> */}

                                                                        </> : <> </>
                                                                    }</Col>
                                                            </> : <> </>
                                                        }
                                                    </Row>
                                                </Accordion.Toggle>
                                            </CardHeader>
                                            <Accordion.Collapse eventKey={dynamicData.step.Status}>
                                                <CardBody>
                                                    <Form id={dynamicData.step.Id} onSubmit={this.handleSubmit}>
                                                        <div className="pl-lg-4">
                                                            <Row>

                                                                {dynamicData.actions.map((dynamicActions) =>
                                                                    <>{dynamicData.step.ViewPermission.includes(this.state.logged_user.PersonRole) ?
                                                                        <>
                                                                            {dynamicActions.action.Name.includes("Ποσότητα") == false && dynamicActions.action.Name.includes("ποσότητα") == false ?
                                                                                <>
                                                                                    <Col lg="6" style={dynamicActions.action.Type == 'delimiter' ? { display: 'none' } : {}}>
                                                                                        <FormGroup>
                                                                                            <br />
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="input-username"
                                                                                            >
                                                                                                {dynamicActions.action.Name}
                                                                                            </label>

                                                                                            {dynamicActions.action.Type == 'file' ?
                                                                                                <>

                                                                                                    <Row>
                                                                                                        <Col lg="4">
                                                                                                            <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                                                                                Επιλογή αρχείου
                                                                                                            </label>
                                                                                                            &nbsp;
                                                                                                            {/* <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                        <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                            <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                                                                            <div id="imageattooltip"></div>
                                                                                        </ReactTooltip> */}
                                                                                                            <Input
                                                                                                                className="form-control-alternative"
                                                                                                                // defaultValue={ dynamicActions.data.Data }
                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                // placeholder={dynamicActions.action.Description}
                                                                                                                style={{ visibility: "hidden" }}
                                                                                                                type={dynamicActions.action.Type}
                                                                                                                // disabled={!dynamicActions.action.Required}
                                                                                                                onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        {dynamicData.step.Status == 3 ?
                                                                                                            <>
                                                                                                                {this.state.showTextAboutRepeat == false ?
                                                                                                                    <>
                                                                                                                        <Col lg="4">
                                                                                                                            <Button
                                                                                                                                color="info"
                                                                                                                                onClick={this.orthiEpanalipsi}
                                                                                                                                size="la"
                                                                                                                            >
                                                                                                                                Ορθή Επανάληψη
                                                                                                                            </Button>
                                                                                                                        </Col>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        <Col lg="4">
                                                                                                                            <Button
                                                                                                                                color="info"
                                                                                                                                onClick={this.orthiEpanalipsi}
                                                                                                                                size="la"
                                                                                                                            >
                                                                                                                                Ορθή Επανάληψη
                                                                                                                            </Button>
                                                                                                                        </Col>
                                                                                                                        <p style={{ color: 'green', marginLeft: '18px', marginTop: '-50px' }}>Η ορθή επανάληψη πραγματοποιήθηκε επιτυχώς!</p>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                            </>
                                                                                                        }
                                                                                                        {/* <Button
                                                                                        color="primary"
                                                                                        onClick={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                        size="sm"
                                                                                    >
                                                                                        Επισύναψη
                                                                                    </Button> */}
                                                                                                    </Row>

                                                                                                    <Table className="align-items-center table-flush" responsive>
                                                                                                        <thead className="thead-light">
                                                                                                            <tr>
                                                                                                                <th scope="col">Ονομα Αρχειου</th>
                                                                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                                                                <th scope="col">Ενεργειες</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                this.state.files.map((dynamicFiles) =>
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                                                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                                                                                    <td>
                                                                                                                                        <Button
                                                                                                                                            color="primary"
                                                                                                                                            href={dynamicFiles.Link}
                                                                                                                                            target="_blank"
                                                                                                                                            size="sm"
                                                                                                                                            title="Προβολή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-eye" />
                                                                                                                                        </Button>
                                                                                                                                        <Button
                                                                                                                                            color="danger"
                                                                                                                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                            size="sm"
                                                                                                                                            title="Διαγραφή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-trash" />
                                                                                                                                        </Button>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </> : <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )}
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                    {/* {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                    <>
                                                                                    <Button
                                                                                        color="warning"
                                                                                        onClick={evt => this.handleOpenModal(dynamicData.step.Id + "/" + dynamicActions.action.Id, dynamicActions.action.Name)}
                                                                                        size="sm"
                                                                                    >
                                                                                        Αναφορά προβλήματος
                                                                                    </Button>
                                                                                    </> : <> </>
                                                                                } */}
                                                                                                </>
                                                                                                : <>{dynamicActions.action.Type == 'button' ?
                                                                                                    <>
                                                                                                        {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                                            <>
                                                                                                                <Col lg="6">
                                                                                                                    <Button
                                                                                                                        color="primary"
                                                                                                                        onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                        size="sm"
                                                                                                                    >
                                                                                                                        {dynamicActions.action.Description}
                                                                                                                    </Button>
                                                                                                                </Col>
                                                                                                                {this.state.buttonCondition == dynamicData.step.Status
                                                                                                                    ?
                                                                                                                    <p>{this.state.buttonMessage}</p>
                                                                                                                    : <> {this.state.buttonCondition == 5 ? <p>{this.state.buttonMessage}</p> : <></>} </>
                                                                                                                }
                                                                                                            </> : <> </>
                                                                                                        }
                                                                                                    </>
                                                                                                    : <>
                                                                                                        {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                                            <>
                                                                                                                {dynamicActions.action.Type == "number" ?
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Name == 'Αριθμός Προηγούμενης Επιθεώρησης' ?
                                                                                                                            <>
                                                                                                                                <Input
                                                                                                                                    className="form-control-alternative"
                                                                                                                                    id={'85d20ba6-3ff1-402c-afc2-16704ecffcf4/b68b1527-8937-4bbf-b8df-a6c3572e0295'}
                                                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                                                    placeholder={'Παρακαλούμε συμπληρώστε τον αριθμό της προηγούμενης μελέτης'}
                                                                                                                                    type={'number'}
                                                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                                                    disabled={!this.state.showRepeatNumber}
                                                                                                                                />
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <Input
                                                                                                                                    className="form-control-alternative"
                                                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                                                    type={dynamicActions.action.Type}
                                                                                                                                    // defaultValue={0}
                                                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                                                />
                                                                                                                            </>

                                                                                                                        }





                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Type == "multidate" ?
                                                                                                                            <>
                                                                                                                                <DatePicker
                                                                                                                                    multiple
                                                                                                                                    className="form-control-alternative"
                                                                                                                                    // value={ dynamicActions.data.Data } 
                                                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                    // onChange={setValues}
                                                                                                                                    value={this.state.datesSelected}
                                                                                                                                    format="DD-MM-YYYY"
                                                                                                                                    onChange={evt => this.updateInputValue(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                                                // onChange={evt => this.multidateEventHandler(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                                                />
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                {dynamicActions.action.Type == "delimiter" ?
                                                                                                                                    <>
                                                                                                                                        <Col lg='6'></Col>
                                                                                                                                        <div className="row" style={{ height: '20px', left: '0px', right: '0px', width: '100%' }}></div>
                                                                                                                                        {/* <Input
                                                                                        className="form-control-alternative"
                                                                                        defaultValue={ dynamicActions.data.Data }
                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                        placeholder={dynamicActions.action.Description}
                                                                                        type={dynamicActions.action.Type}
                                                                                        /> */}
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <>
                                                                                                                                        {dynamicActions.action.Type == "dropdown" ?
                                                                                                                                            <>
                                                                                                                                                {dynamicActions.action.Name == 'Νέα Επιθεώρηση ή Ορθή Επανάληψη' ?
                                                                                                                                                    <>
                                                                                                                                                        <Select options={epitheorisi_options} defaultValue={{ label: dynamicActions.data.Data, value: dynamicActions.data.Data }} onChange={e => { this.selectChange(e, ('85d20ba6-3ff1-402c-afc2-16704ecffcf4/93b80340-abd7-4384-9d0f-d619243a25af')) }} />
                                                                                                                                                    </>
                                                                                                                                                    : <></>
                                                                                                                                                }
                                                                                                                                                {dynamicActions.action.Name == 'Προμηθευτής' ?
                                                                                                                                                    <>
                                                                                                                                                        <Select options={this.state.suppliers_dropdown} defaultValue={{ label: dynamicActions.data.Data, value: dynamicActions.data.Data }} onChange={e => { this.selectChange(e, ('85d20ba6-3ff1-402c-afc2-16704ecffcf4/6bac0157-4b69-455b-b3f7-09725c0b45ae')) }} />
                                                                                                                                                    </>
                                                                                                                                                    : <></>
                                                                                                                                                }
                                                                                                                                                {dynamicActions.action.Name == 'Τοποθεσία επιθεώρησης' ?
                                                                                                                                                    <>
                                                                                                                                                        <Select options={this.state.locations_dropdown} defaultValue={{ label: dynamicActions.data.Data, value: dynamicActions.data.Data }} onChange={e => { this.selectChange(e, ('85d20ba6-3ff1-402c-afc2-16704ecffcf4/86aa0ad1-34f6-419d-85fe-7d4f5ecbd922')) }} />
                                                                                                                                                    </>
                                                                                                                                                    : <></>
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            <><><Input
                                                                                                                                                className="form-control-alternative"
                                                                                                                                                defaultValue={dynamicActions.data.Data}
                                                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                                                placeholder={dynamicActions.action.Description}
                                                                                                                                                type={dynamicActions.action.Type}
                                                                                                                                                onChange={evt => this.updateInputValue(evt)}
                                                                                                                                            /></></>
                                                                                                                                        }
                                                                                                                                    </>

                                                                                                                                }
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <Input
                                                                                                                    className="form-control-alternative"
                                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                                    type={dynamicActions.action.Type}
                                                                                                                    disabled
                                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                                />
                                                                                                            </>
                                                                                                        }
                                                                                                        {/* {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                    <>
                                                                                    <Button
                                                                                        color="warning"
                                                                                        onClick={evt => this.handleOpenModal(dynamicData.step.Id + "/" + dynamicActions.action.Id, dynamicActions.action.Name)}
                                                                                        size="sm"
                                                                                    >
                                                                                        Αναφορά προβλήματος
                                                                                    </Button>
                                                                                    </> : <> </>
                                                                                } */}
                                                                                                    </>
                                                                                                }
                                                                                                </>
                                                                                            }
                                                                                        </FormGroup>

                                                                                    </Col>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {/* <Cascader
                                                                                            style={{ width: '100%' }}
                                                                                            className={'dropdown'}
                                                                                            placeholder={"Κατηγορία / Υποκατηγορία"}
                                                                                            // menuWidth={400}
                                                                                            // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                            // onChange={(e) => { this.cascaderonChange((dynamicData.step.Id + "/" + dynamicActions.action.Id), e) }}
                                                                                            options={options}
                                                                                        /> */}
                                                                                    {dynamicActions.data.Data != "" ?
                                                                                        <>
                                                                                            <Col lg="6" style={{ marginTop: '20px' }}>
                                                                                                <label
                                                                                                    className="form-control-label"

                                                                                                >
                                                                                                    {dynamicActions.action.Description}
                                                                                                </label>
                                                                                                <Input
                                                                                                    className={"form-control-alternative"}

                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    placeholder={"Ποσότητα"}
                                                                                                    type={"number"}
                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                                    disabled={dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ? false : true}
                                                                                                // disabled
                                                                                                />
                                                                                            </Col>
                                                                                        </>
                                                                                        :
                                                                                        <>

                                                                                        </>
                                                                                    }


                                                                                </>}

                                                                        </>
                                                                        :
                                                                        <>

                                                                        </>}
                                                                    </>

                                                                )}
                                                                {
                                                                    dynamicData.step.Status == 1 ?
                                                                        <>
                                                                            {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                <>
                                                                                    <Col lg="12">
                                                                                        <br />
                                                                                        {this.state.showAddNewButton ?
                                                                                            <>
                                                                                                <Button size="sm" color="primary" onClick={this.handleNewCategory}> Προσθήκη Νέας Κατηγορίας / Υποκατηγορίας</Button> <br /><br />
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                        }

                                                                                        {this.state.category_set.map((set, index) =>
                                                                                            <>
                                                                                                {set ?
                                                                                                    <>
                                                                                                        <br />
                                                                                                        <Col lg="6"></Col>
                                                                                                        <Col lg="6">
                                                                                                            <label
                                                                                                                className="form-control-label"
                                                                                                                htmlFor="input-username"
                                                                                                            >
                                                                                                                Κατηγορία / Υποκατηγορία
                                                                                                            </label>
                                                                                                            <Cascader
                                                                                                                style={{ width: '100%' }}
                                                                                                                className={'dropdown'}
                                                                                                                placeholder={"Κατηγορία / Υποκατηγορία"}
                                                                                                                onChange={(e) => { this.handleCascaderChange(e, "amount" + String(index)) }}
                                                                                                                // menuWidth={400}
                                                                                                                // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                // onChange={(e) => { this.cascaderonChange((dynamicData.step.Id + "/" + dynamicActions.action.Id), e) }}
                                                                                                                options={options}
                                                                                                            />
                                                                                                            <Input
                                                                                                                className={"form-control-alternative" + " amount" + String(index)}
                                                                                                                // class={'dummy3'}
                                                                                                                // defaultValue={dynamicActions.data.Data}
                                                                                                                // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                placeholder={"Ποσότητα"}
                                                                                                                type={"number"}
                                                                                                                // defaultValue={0}
                                                                                                                onChange={evt => this.updateInputValue(evt)}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </Col>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )}


                                                                                    </Col>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            }

                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                                {
                                                                    dynamicData.step.Status == 3 ?
                                                                        <>
                                                                            {dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) ?
                                                                                <>
                                                                                    <Col lg="12">
                                                                                        <br />
                                                                                        {this.state.acceptedShowAddNewButton ?
                                                                                            <>
                                                                                                <Button size="sm" color="primary" onClick={this.acceptedHandleNewCategory}>Ενημέρωση Αποδεκτής/Απορριφθείσας Κατηγορίας Ξυλείας</Button> <br /><br />
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                        }

                                                                                        {this.state.accepted_category_set.map((set, index) =>
                                                                                            <>
                                                                                                {set ?
                                                                                                    <>
                                                                                                        <br />
                                                                                                        <Col lg="6"></Col>
                                                                                                        <Col lg="6">
                                                                                                            <label
                                                                                                                className="form-control-label"
                                                                                                                htmlFor="input-username"
                                                                                                            >
                                                                                                                Κατηγορία / Υποκατηγορία
                                                                                                            </label>
                                                                                                            <Cascader
                                                                                                                style={{ width: '100%' }}
                                                                                                                className={'dropdown'}
                                                                                                                placeholder={"Κατηγορία / Υποκατηγορία"}
                                                                                                                onChange={(e) => { this.acceptedHandleCascaderChange(e, "accepted_amount" + String(index)) }}
                                                                                                                // menuWidth={400}
                                                                                                                // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                // onChange={(e) => { this.cascaderonChange((dynamicData.step.Id + "/" + dynamicActions.action.Id), e) }}
                                                                                                                options={accepted_options}
                                                                                                            />
                                                                                                            <Input
                                                                                                                className={"form-control-alternative" + " accepted_amount" + String(index)}
                                                                                                                // class={'dummy3'}
                                                                                                                // defaultValue={dynamicActions.data.Data}
                                                                                                                // id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                placeholder={"Ποσότητα"}
                                                                                                                type={"number"}
                                                                                                                // defaultValue={0}
                                                                                                                onChange={evt => this.updateInputValue(evt)}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </Col>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )}


                                                                                    </Col>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                            }

                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </Row>
                                                        </div>
                                                    </Form>
                                                </CardBody>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        )}
                    </Accordion>

                    <Modal
                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        style={{
                            overlay: {

                            },
                            content: {
                                position: 'absolute',
                                top: '40%',
                                left: '30%',
                                marginTop: '-50px',
                                marginLeft: '-30px',
                                width: '35%',
                                height: '30%'
                            }
                        }}
                    >
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                        >
                            Σχολιο για {this.state.modalFieldName}
                        </label>
                        <Input
                            className="form-control-alternative"
                            defaultValue=''
                            id='comment'
                            placeholder='Προσθέστε το σχόλιό σας'
                            onChange={evt => this.updateComment(evt)}
                        // type={dynamicActions.action.Type}
                        />
                        <p></p>
                        <Button
                            color="primary"
                            onClick={this.handleCloseModalSubmit}
                            size="sm"
                        >
                            Προσθήκη σχολίου
                        </Button>
                        {/* <Button
                        color="primary"
                        onClick={this.handleCloseModal}
                        size="sm"
                    >
                        Απενεργοποίηση σχολίου
                    </Button> */}
                        <button onClick={this.handleCloseModal}>Κλείσιμο</button>
                    </Modal>
                </>
            );
        }
    }
}

export default Instance;
