/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      roles: [],
      role: '',
      name: '',
      email: '',
      created: false,
      newUser: []
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;
    // console.log(handle)

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  createUser = userId => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/user/new/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'DisplayName': this.state.name,
          'Email': this.state.email,
          'Roles': this.state.role
        })
      })
        .then(data => {
          // console.log(data);
          window.location.reload(false);
        })
    }
  }

  updateName(evt) {
    // console.log(evt.target.value);
    this.setState({
      name: evt.target.value
    })
  }

  updateRole(evt) {
    // console.log(evt.target.value);
    this.setState({
      role: evt.target.value
    })
  }

  updateEmail(evt) {
    // console.log(evt.target.value);
    this.setState({
      email: evt.target.value
    })
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Δημιουργία νέου χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={() => { this.createUser() }}
                        size="sm"
                      >
                        Δημιουργία
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ονοματεπώνυμο
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder=""
                              type="email"
                              onChange={evt => this.updateEmail(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ρόλος
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder=""
                              type="select"
                              defaultValue='Mechanic'
                              onChange={evt => this.updateRole(evt)}
                            >
                              {this.state.roles.map((dynamicRoles) =>
                                <option>{dynamicRoles.Name}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewUser;
