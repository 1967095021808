import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';

class Supplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            isLoaded: false,
            supplier: [],
            name: '',
            user: []
        };
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;
        // console.log(handle)

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/supplier/suppliers/" + handle + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            supplier: result,
                            name: result.SupplierName
                        });
                        console.log(result);
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };

        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    this.setState({
                        isLoaded: true,
                        user: result,
                    });
                },
                (error) => {
                    // console.log(error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    updateName(evt) {
        // console.log(evt.target.value);
        this.setState({
            name: evt.target.value
        })
    }

    saveChanges = userId => {
        axios.put(process.env.REACT_APP_API_LINK + '/api/supplier/update/', {
            data: { 'SupplierName': this.state.name },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                // console.log(res.data);
            })
    }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Πληροφορίες Προμηθευτή</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            {this.state.user.PersonRole == 'Admin' ?
                                                <>
                                                    <Button
                                                        color="primary"
                                                        href="#pablo"
                                                        onClick={() => { this.saveChanges(this.state.supplier.Id) }}
                                                        size="sm"
                                                    >
                                                        Αποθήκευση αλλαγών
                                                    </Button>
                                                </> : <> </>}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Βασικα στοιχεια
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Προμηθευτής
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={this.state.supplier.SupplierName}
                                                            id="input-username"
                                                            placeholder=""
                                                            type="text"
                                                            onChange={evt => this.updateName(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Supplier;
