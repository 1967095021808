import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import Login from "views/examples/Login.js";

function setToken(userToken) {
    localStorage.setItem('token', userToken);
}

async function verifyToken(token) {
    //here
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })
        // .then(res => res.json())
        .then(
            (result) => {
                // console.log(result.status);
                if (result.status != 200) {
                    localStorage.removeItem('token');
                    window.location.replace("/auth/login");
                }
                else {
                    localStorage.setItem('token', token);
                }
            },
            (error) => {
                // console.log(error);
                localStorage.removeItem('token');
                window.location.replace("/auth/login");
            }
        )
};

function App() {
    const token = localStorage.getItem('token');

    // console.log(token);

    if (!token) {
        // console.log('no token');
        return <Login setToken={setToken} />
    }
    else{
        // console.log('check token');
        verifyToken(token);
        // console.log(token);

        return (
            <BrowserRouter>
            <Switch>
                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                <Route path="/auth" render={props => <Login {...props} />} />
                <Redirect from="/" to="/admin/index"/>
            </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
