import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';


class InstancesWithStatusTwo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            instances: [],
            user: [],
            loadedDatatable: false
        };
    }

    fetchInstancesWithStatusTwo = user => {
        fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/filteredincomplete/2", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        instances: result,
                    });
                    // console.log(result);

                    result.forEach(function (part, index) {
                        this[index].Buttons =
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    href={"/admin/instance/" + part[0].Id}
                                    size="sm"
                                    title="Επεξεργασία"
                                >
                                    <i className="fas fa-eye" />
                                </Button>
                                {user.PersonRole == 'Admin' ?
                                    <>
                                        {/* <Button
                      color="danger"
                      onClick={() => { this.handleClick(this[index].Id) }}
                      size="sm"
                      title="Διαγραφή"
                    >
                      <i className="fas fa-trash" />
                    </Button> */}
                                    </> : <> </>}



                                {/* {this.state.user.PersonRole == 'Admin' ?
                  <>
                    <Button
                      color="danger"
                      // onClick={() => { this.handleClick(dynamicData.Id) }}
                      size="sm"
                      title="Διαγραφή"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </> : <> </>} */}
                            </React.Fragment>


                        this[index].NewStatus = part[0].Status == 1 ? "1. Αναγγελία" : part[0].Status == 2 ? "2. Προγραμματισμός Επιθεώρησης" : part[0].Status == 3 ? "3. Απαιτείται συμπλήρωση των στοιχείων επιθεώρησης" : part[0].Status == 4 ? "4. ΔΕΔΔΗΕ Ολοκληρώθηκε" : "";
                        this[index].Program = "Επιθεωρήσεις Ξύλινων Υλικών";
                        //const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                        var ress = part[1]
                        ress = "{" + ress + "}";
                        ress = ress.replace(/[\n\r\t]/g, '');
                        const obj = JSON.parse(ress);
                        //var chars = part[1].json();
                        this[index].SerialAnaggelias = obj["Αριθμός"];
                        this[index].DateAnaggelias = obj["Ημερομηνία"];
                        this[index].Promitheutis = obj["Προμηθευτής"];
                        this[index].SerialEkthesis = obj["Αριθμός έκθεσης"];//chars[0] + " " + chars[1] + " " + chars[2] + " " + chars[14];
                        this[index].SerialNumber = part[0].SerialNumber;
                        var temp_date = new Date(part[0].SubmittedAt);
                        this[index].SubmittedAt = temp_date.toLocaleString('el-GR');

                    }, result);

                    if (user != '') {
                        this.setState({
                            loadedDatatable: true
                        });
                    }

                    this.state.dummy = {
                        columns: [
                            {
                                label: 'Α/Α ▼',
                                field: 'SerialNumber',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Μεταβαση',
                                field: 'Buttons',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Αριθμος Αναγγελιας ▼',
                                field: 'SerialAnaggelias',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Ημερομηνια Αναγγελιας ▼',
                                field: 'DateAnaggelias',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Προμηθευτης ▼',
                                field: 'Promitheutis',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Αριθμος εκθεσης ▼',
                                field: 'SerialEkthesis',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Κατασταση ▼',
                                field: 'NewStatus',
                                sort: 'asc',
                                width: 100
                            },
                            {
                                label: 'Τροποποιηθηκε Απο ▼',
                                field: 'EditedBy',
                                sort: 'asc',
                                width: 150
                            },
                            {
                                label: 'Ημ/νία Δημιουργίας ▼',
                                field: 'SubmittedAt',
                                sort: 'asc',
                                width: 150
                            },
                            // {
                            //   label: 'Email ▼',
                            //   field: 'Email',
                            //   sort: 'asc',
                            //   width: 150
                            // },


                        ],
                        rows: this.state.instances,
                    };
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    async componentDidMount() {


        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    this.setState({
                        isLoaded: true,
                        user: result,
                    });
                },
                (error) => {
                    // console.log(error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    handleClick = instanceId => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + instanceId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    window.location.reload(false);
                })
        }
    }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Επιθεωρήσεις</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ?
                                                <>
                                                    <Button
                                                        color="success"
                                                        href="/admin/newinstance"
                                                        size="sm"
                                                    >
                                                        Νέα επιθεώρηση
                                                    </Button>
                                                </> : <> </>}
                                        </Col>
                                    </Row>

                                </CardHeader>
                                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Α/Α</th> */}
                                {/* <th scope="col">Αρ. Πρωτοκολλου</th> */}
                                {/* <th scope="col">Προγραμμα</th>
                      <th scope="col">Κατασταση</th>
                      <th scope="col">Τελ. Ενημερωση</th> */}
                                {/* <th scope="col">Ανατεθηκε σε</th> */}
                                {/* <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.instances.map((dynamicData) =>
                        <tr>
                          <th scope="row"> {dynamicData.SerialNumber}/{new Date().getFullYear()}</th> */}
                                {/* <td> {dynamicData.ProtocolNumber} </td> */}
                                {/* <td>{dynamicData.workflow}</td>
                          {dynamicData.Status == 1 ? <td>1. Αίτηση Σύνδεσης</td> : <> </>}
                          {dynamicData.Status == 2 ? <td>2. Πρόσθετα Δικαιολογητικά</td> : <> </>}
                          {dynamicData.Status == 3 ? <td>3. Τεχνική Αξιολόγηση Ακινήτου</td> : <> </>}
                          {dynamicData.Status == 4 ? <td>4. Σύνταξη Προσφοράς</td> : <> </>}
                          {dynamicData.Status == 5 ? <td>5. Υπογραφή Σύμβασης</td> : <> </>}
                          {dynamicData.Status == 6 ? <td>6. Μηχανική Ολοκλήρωση</td> : <> </>}
                          {dynamicData.Status == 7 ? <td>7. Μελέτη εσωτερικής εγκατάστασης</td> : <> </>}
                          {dynamicData.Status == 8 ? <td>8. Σύμβαση με Προμηθευτή</td> : <> </>}
                          {dynamicData.Status == 9 ? <td>9. Ενεργοποίηση εσωτερικής εγκατάστασης</td> : <> </>}
                          {dynamicData.Status == 10 ? <td>10. Φύλλο Καύσης</td> : <> </>}
                          {dynamicData.Status == 11 ? <td>11. Έκδοση άδειας χρήσης</td> : <> </>}
                          <td>{Moment(dynamicData.SubmittedAt, "YYYYMMDD").fromNow()}</td> */}
                                {/* <td></td> */}
                                {/* <td><Button
                            color="primary"
                            href={"/admin/instance/" + dynamicData.Id}
                            size="sm"
                            title="Επεξεργασία"
                          >
                            <i className="fas fa-eye" />
                          </Button>
                            {this.state.user.PersonRole == 'Admin' ?
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => { this.handleClick(dynamicData.Id) }}
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </> : <> </>}
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table> */}

                                {
                                    this.state.loadedDatatable == false ?
                                        this.fetchInstancesWithStatusTwo(this.state.user)
                                        :
                                        <></>
                                }

                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <MDBDataTable
                                                responsive
                                                bordered
                                                exportToCSV
                                                hover
                                                strip
                                                data={this.state.dummy}
                                                id="mdbdttable"
                                                entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                noDataComponent="Δεν υπάρχουν δεδομένα"
                                                searchLabel="Αναζήτηση"
                                                paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                            />
                                        </div>

                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default InstancesWithStatusTwo;
