import React from "react";

// reactstrap components
import { Card, Button, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: []
    };
  };

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/count/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);

            this.setState({
              totalCount: result.count
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/headerstats/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);

            this.setState({
              stats: result
            });
          }
        )
    };
  }

  render() {
    return (
      <>
        <div className="header pb-8 pt-3 pt-md-7" style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="8" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text text-muted mb-0"
                          >
                            <a href="/admin/instanceswithstatustwo/">Βήμα 1ο: Αναγγελία Ετοιμότητας</a>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.stats.second}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>{" "}
                        <span className="text-nowrap"></span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="8" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text text-muted mb-0"
                          >
                            <a href="/admin/instanceswithstatusthree/">Βήμα 2ο: Προγραμματισμός Επιθεώρησης</a>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.stats.third}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>{" "}
                        <span className="text-nowrap"></span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="8" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text text-muted mb-0"
                          >
                            <a href="/admin/instanceswithstatusfour/">Βήμα 3ο: Τεχνική Έκθεση</a>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.stats.fourth}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>{" "}
                        <span className="text-nowrap"></span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="8" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text text-muted mb-0"
                          >
                            <a href="/admin/oldinstances/">Βήμα 4ο: Ε.Π.Υ (Ολοκληρωμένες Επιθεωρήσεις)</a>
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.stats.completed}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>{" "}
                        <span className="text-nowrap"></span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

              </Row>


            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
