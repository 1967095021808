import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import InstanceHeader from "components/Headers/InstanceHeader.js";

import { CommonLoading } from 'react-loadingg';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Moment from "moment";
import 'moment/locale/el';
import 'leaflet/dist/leaflet.css';

import axios from 'axios';
import Modal from 'react-modal';

import L from "leaflet";

const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

class InstanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      details: [],
      files: [],
      owner: [],
      mechanic: [],
      ikasp: '',
      supplier: '',
      meter: '',
      user: [],
      price: 0,
      modalFieldName: '',
      modalFieldLastName: '',
      modalFieldEmail: '',
      modalFieldPhone: '',
      yellow: "#FFD712"
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseMeterModal = this.handleCloseMeterModal(this);
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    // console.log(handle);

    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              details: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              files: result,
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/ikasp/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              ikasp: result
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/meter/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              meter: result.Id
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/pricing/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              price: result.Price,
              datePrice: result.dateCalculated
            });
          },
          (error) => {
            this.setState({
              error
            });
          }
        )

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            // console.log(error);
            this.setState({
              isLoaded: true,
              error
            });
          }
        )

      fetch(process.env.REACT_APP_API_LINK + "/api/suppliers/instance/" + handle + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
            this.setState({
              loading: false,
              supplier: result.SupplierId
            });
          },
          (error) => {
            this.setState({
              loading: false,
              error
            });
          }
        )
    };
  }

  handleOpenModal(choice) {
    if (choice == 1) {
      // get owner data
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/ownerdata/' + this.state.details.Id + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          // console.log(data);
          this.setState({
            modalFieldName: data.name,
            modalFieldLastName: data.lastname,
            modalFieldEmail: data.email,
            modalFieldPhone: data.phone,
            showModal: true,
          });
        })
    }
    else if (choice == 2) {
      // get mechanic data
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/mechanicdata/' + this.state.details.Id + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          // console.log(data);
          this.setState({
            modalFieldName: data.name,
            modalFieldLastName: data.lastname,
            modalFieldEmail: data.email,
            modalFieldPhone: data.phone,
            showModal: true,
          });
        })
    }
    else if (choice == 3) {
      // get meter data
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/meterdata/' + this.state.details.Id + '/', {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          // console.log(data);
          this.setState({
            modalFieldName: data.meter_type,
            modalFieldLastName: data.serial,
            showModalMeter: true,
          });
        })
    }
    else if (choice == 4) {
      this.setState({
        modalFieldName: 'test',
        modalFieldLastName: 'test',
        modalFieldEmail: 'test',
        modalFieldPhone: 'test',
        showModal: true,
      });
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseMeterModal() {
    this.setState({ showModalMeter: false });
  }

  recallStep = status => {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/recall/' + this.state.details.Id + '/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
      })
        .then(data => {
          // console.log(data);
          window.location.reload(false);
        })
    }
  }

  // updateComment(evt) {
  //   // console.log(evt.target.value);
  //   this.setState({
  //     name: evt.target.value
  //   })
  // }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      )
    }
    else {
      return (
        <>
          <GenericHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Αίτησης</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                          <>
                            <Button
                              color="primary"
                              href="#pablo"
                              onClick={() => { this.saveChanges(this.state.details.Id) }}
                              size="sm"
                            >
                              Αποθήκευση αλλαγών
                            </Button>
                          </> : <> </> } */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                              // disabled={true}
                              // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Έναρξης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={Moment(this.state.details.StartedAt).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                              // disabled={true}
                              // onChange={evt => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αίτησης
                              </label>
                              {this.state.details.Status == 1 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='1. Αίτηση Σύνδεσης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 2 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='2. Πρόσθετα Δικαιολογητικά'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 3 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='3. Τεχνική Αξιολόγηση Ακινήτου'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 4 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='4. Σύνταξη Προσφοράς'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 5 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='5. Υπογραφή Σύμβασης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 6 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='6. Μηχανική Ολοκλήρωση'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 7 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='7. Μελέτη εσωτερικής εγκατάστασης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 8 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='8. Σύμβαση με Προμηθευτή'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 9 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='9. Ενεργοποίηση εσωτερικής εγκατάστασης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 10 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='10. Φύλλο Καύσης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                              {this.state.details.Status == 11 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='11. Έκδοση άδειας χρήσης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </>}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>

                          <Col md="6" >
                            <h6 className="heading-small text-muted mb-4">
                              Επιλογες Αιτησης
                            </h6>
                            <Button
                              color="primary"
                              href={"/admin/instance/" + this.state.details.Id}
                              size="lg"
                            >
                              Βήματα αίτησης
                            </Button>
                            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' || this.state.user.PersonRole == 'Sales' || this.state.user.PersonRole == 'Marketing' || this.state.user.PersonRole == 'Maintenance' || this.state.user.PersonRole == 'Mechanic' ?
                              <>
                                <Button
                                  color="warning"
                                  href={"/admin/commenthistory/" + this.state.details.Id}
                                  size="lg"
                                >
                                  Ιστορικό σχολίων
                                </Button>
                              </> : <> </>}
                            <Button
                              href="#pablo"
                              // style={{ backgroundColor: this.state.yellow }}
                              // color={this.state.yellow}
                              color='primary'
                              size="lg"
                            >
                              Ρυθμίσεις
                            </Button>
                            {this.state.user.PersonRole == 'Admin' ?
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => { this.recallStep() }}
                                  size="lg"
                                >
                                  Ανάκληση πρόσφατου βήματος
                                </Button>
                              </> : <> </>}
                          </Col>

                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολιο Αιτησης
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={this.state.details.Comments}
                              placeholder="Τηλέφωνο"
                              type="textarea"
                            // disabled={true}
                            // onChange={evt => this.updatePhone(evt)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' ?
              <>
                <Row className="mt-5">
                  <Col className="mb-5 mb-xl-0" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Έγγραφα</h3>
                          </Col>
                        </Row>

                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Ονομα αρχειου</th>
                            <th scope="col">Ημερομηνια Αναρτησης</th>
                            <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.files.map((dynamicFiles) =>
                              <tr>
                                <td>{dynamicFiles.FileName}</td>
                                <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                <td>
                                  <Button
                                    color="primary"
                                    href={dynamicFiles.Link}
                                    size="sm"
                                    title="Προβολή"
                                  >
                                    <i className="fas fa-eye" />
                                  </Button>
                                  {this.state.user.PersonRole == 'Admin' ?
                                    <>
                                      <Button
                                        color="danger"
                                        // href={dynamicFiles.Link}
                                        size="sm"
                                        title="Διαγραφή"
                                      >
                                        <i className="fas fa-trash" />
                                      </Button>
                                    </> : <> </>}
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                </Row>
              </> : <> </>}
          </Container>

          <Modal
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {

              },
              content: {
                position: 'absolute',
                top: '30%',
                left: '30%',
                marginTop: '-50px',
                marginLeft: '-30px',
                width: '35%',
                height: '40%'
              }
            }}
          >
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Όνομα
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldName}
              id='comment'
              placeholder=''
            />
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Επίθετο
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldLastName}
              id='comment'
              placeholder=''
            />
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Τηλέφωνο
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldPhone}
              id='comment'
              placeholder=''
            />
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Email
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldEmail}
              id='comment'
              placeholder=''
            />
            <p></p>
            <Button
              color="primary"
              onClick={this.handleCloseModal}
              size="sm"
              title="Κλείσιμο"
            >
              <i className="fas fa-times" /> &nbsp; Κλείσιμο
            </Button>
          </Modal>

          <Modal
            isOpen={this.state.showModalMeter}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {

              },
              content: {
                position: 'absolute',
                top: '30%',
                left: '30%',
                marginTop: '-50px',
                marginLeft: '-30px',
                width: '35%',
                height: '40%'
              }
            }}
          >
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Τύπος Μετρητή
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldName}
              id='comment'
              placeholder=''
            />
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
              Σειριακός Αριθμός
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldLastName}
              id='comment'
              placeholder=''
            />
            <p></p>
            <Button
              color="primary"
              onClick={this.handleCloseMeterModal}
              size="sm"
              title="Κλείσιμο"
            >
              <i className="fas fa-times" /> &nbsp; Κλείσιμο
            </Button>
          </Modal>
        </>
      );
    }
  }
}

export default InstanceDetails;
