import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class GenericHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-4 pt-5" style={{background: "linear-gradient(to right, #00467F, #5CBC59)"}}>
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default GenericHeader;
