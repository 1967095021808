import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

class AdminNavbar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: []
    };
}

componentDidMount() {
    if (localStorage.getItem('token')) {
        fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization' : 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/x-www-form-urlencoded'
        })
        })
        .then(res => res.json())
        .then(
        (result) => {
            this.setState({
            isLoaded: true,
            user: result,
            });
        },
        (error) => {
            this.setState({
            isLoaded: true,
            error
            });
        }
        )
    };
  }

  handleClick = instanceId => {
    localStorage.removeItem('token');
  }

  

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {/* {this.props.brandText} */}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        <i className="fa fa-bell" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem>
                      <p style={{color: "blue"}} > Yποβλήθηκε νέα αίτηση <strong>Α/Α 28/2021</strong></p>
                  </DropdownItem>
                  <DropdownItem>
                  <p style={{color: "blue"}} > Yποβλήθηκε νέα αίτηση <strong>Α/Α 29/2021</strong></p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/team-4-800x800.jpg")}
                      />
                    </span> */}
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.user.DisplayName}  <i className="fa fa-caret-down" />
                      </span>
                      
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/admin/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Προφίλ</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Ρυθμίσεις</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Αρχείο Δραστηριοτήτων</span>
                  </DropdownItem> */}
                  {/* <DropdownItem to="/admin/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={() => { this.handleClick() }}>
                    <i className="ni ni-button-power" />
                    <span>Αποσύνδεση</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
