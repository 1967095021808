import Index from "views/Index.js";
import Login from "views/examples/Login.js";
import Users from "views/examples/Users.js";
import User from "views/examples/User.js";
import NewUser from "views/examples/newUser.js";
import Locations from "views/examples/Locations.js";
import Location from "views/examples/Location.js";
import NewLocation from "views/examples/newLocation.js";
import InstanceMenu from "views/examples/InstanceMenu.js";
import Instances from "views/examples/Instances.js";
import OldInstances from "views/examples/OldInstances.js";
import InstancesWithStatusTwo from "views/examples/InstancesWithStatusTwo";
import InstancesWithStatusThree from "views/examples/InstancesWithStatusThree";
import InstancesWithStatusFour from "views/examples/InstancesWithStatusFour";
import InstanceDetails from "views/examples/InstanceDetails.js";
import Instance from "views/examples/Instance.js";
import newInstance from "views/examples/newInstance.js";
import createInstance from "views/examples/createInstance.js";
import Workflows from "views/examples/Workflows.js";
import Workflow from "views/examples/Workflow.js";
import WorkflowSteps from "views/examples/WorkflowSteps.js";
import StepActions from "views/examples/StepActions.js";
import Documents from "views/examples/Documents.js";
import Profile from "views/examples/Profile.js";
import Settings from "views/examples/Settings.js"
import Suppliers from "views/examples/Suppliers";
import Supplier from "views/examples/Supplier";
import NewSupplier from "views/examples/newSupplier";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Χρήστες",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/user/:id",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: User,
    layout: "/admin"
  },
  {
    path: "/newuser",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: NewUser,
    layout: "/admin"
  },
  {
    path: "/locations",
    name: "Τοποθεσίες",
    icon: "ni ni-single-02 text-yellow",
    component: Locations,
    layout: "/admin"
  },
  {
    path: "/location/:id",
    name: "Τοποθεσία",
    icon: "ni ni-single-02 text-yellow",
    component: Location,
    layout: "/admin"
  },
  {
    path: "/newLocation",
    name: "Τοποθεσία",
    icon: "ni ni-single-02 text-yellow",
    component: NewLocation,
    layout: "/admin"
  },
  {
    path: "/suppliers",
    name: "Προμηθευτές",
    icon: "ni ni-single-02 text-yellow",
    component: Suppliers,
    layout: "/admin"
  },
  {
    path: "/supplier/:id",
    name: "Προμηθευτής",
    icon: "ni ni-single-02 text-yellow",
    component: Supplier,
    layout: "/admin"
  },
  {
    path: "/newSupplier",
    name: "Προμηθευτής",
    icon: "ni ni-single-02 text-yellow",
    component: NewSupplier,
    layout: "/admin"
  },
  {
    path: "/instancemenu",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceMenu,
    layout: "/admin"
  },
  {
    path: "/instances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: Instances,
    layout: "/admin"
  },
  {
    path: "/oldinstances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: OldInstances,
    layout: "/admin"
  },
  {
    path: "/instanceswithstatustwo",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: InstancesWithStatusTwo,
    layout: "/admin"
  },
  {
    path: "/instanceswithstatusthree",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: InstancesWithStatusThree,
    layout: "/admin"
  },
  
  {
    path: "/instanceswithstatusfour",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: InstancesWithStatusFour,
    layout: "/admin"
  },
  {
    path: "/instance/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: Instance,
    layout: "/admin"
  },
  {
    path: "/newinstance",
    name: "Nέα Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: newInstance,
    layout: "/admin"
  },
  {
    path: "/createinstance",
    name: "Δημιουργία Ροής",
    icon: "ni ni-circle-08 text-pink",
    component: createInstance,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Προφίλ χρήστη",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
];

export default routes;
